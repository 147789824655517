import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../components/subscribeForm/form.css";

function Form() {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      setIsEmailValid(true);
      setEmail("");
    } else {
      setIsEmailValid(false);
      setTimeout(() => {
        setIsEmailValid(true);
      }, 4000);
      return;
    }
    try {
      const response = await fetch(
        "https://avvic-project-api.onrender.com/newsletterSubscriber/store",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
          }),
        }
      );

      if (response.ok) {
        // Request successful
        toast.success("Successfully subscribed to the newsletter", {
          // position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      } else {
        // Request failed
        toast.warning("Failed to store subscriber data", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error(error);
      toast.warning("Failed to send email", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

 ;

  return (
    <>
      {" "}
      <div className="contact-us">
        <form onSubmit={handleSubmit}>
          <input
            typeof="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id={!isEmailValid ? "invalid" : ""}
            className="contact-form"
            required
          ></input>
          <button className="contact-btn">Subscribe</button>
          {!isEmailValid && (
            <p className="error" style={{ color: "red", textAlign: "center" }}>
              Please enter a valid email address.
            </p>
          )}
        </form>
        <ToastContainer />
      </div>
      <p className="subscribe-content">
        By subscribing you agree to with our Privacy Policy and provide consent
        to receive updates from our <br /> company.
      </p>
    </>
  );
}

export default Form;

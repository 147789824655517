import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../modalBox/modal.css";

const Modal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isphoneNumberValid, setIsphoneNumberValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateEmail(email)) {
      setIsEmailValid(true);
      setEmail("");
    } else {
      setIsEmailValid(false);
      setTimeout(() => {
        setIsEmailValid(true);
      }, 3000);
      return;
    }
    try {
      const response = await fetch(
        "https://avvic-project-api.onrender.com/newsletterSubscriber/store",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: name,
            email: email,
            phoneNumber: phoneNumber,
          }),
        }
      );

      if (response.ok) {
        // Request successful
        toast.success("Successfully subscribed to the newsletter", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          onClose: onClose,
        });
        // Reset name and phoneNumber inputs
        setName("");
        setValue("");
      } else {
        // Request failed
        throw new Error("Failed to store subscriber data");
      }
    } catch (error) {
      console.error(error);
      toast.warning("Failed to send email", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const handleNameChange = (event) => {
    const inputName = event.target.value;
    setName(inputName);

    // Regular expression pattern for name validation
    const nameRegex = /^[a-zA-Z\s]+$/; // Only alphabets and spaces allowed

    // Check if the entered name matches the regex pattern
    const isValidName = nameRegex.test(inputName);
    setIsValid(isValidName);
  };

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;

    // Regular expression pattern for number validation
    const numberRegex = /^[0-9+]+$/; // Only numbers 1-9 and + sign allowed

    // Check if the entered value matches the regex pattern
    const isValidNumber = numberRegex.test(inputValue);
    setIsphoneNumberValid(isValidNumber);

    setPhoneNumber(inputValue);
  };

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h1 className="subscribe-head-text">Subscribe</h1>
        <p className="subscribe-text-content">
          {" "}
          Join our newsletter to stay up to date on latest information.
        </p>
        <div className=" modal-form">
          <form onSubmit={handleSubmit}>
            <div className="formInput-container">
              <input
                type="text"
                id="name"
                value={name}
                onChange={handleNameChange}
                placeholder="Enter your name"
                name="name"
                className="newsfeed-form"
                required
              ></input>
              {!isValid && (
                <p style={{ color: "red", textAlign: "center" }}>
                  Invalid name. Only alphabets and spaces allowed.
                </p>
              )}
            </div>

            <div className="formInput-container">
              <input
                type="tel"
                placeholder="Enter phone number"
                name="phoneNumber"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                className="newsfeed-form"
                required
              ></input>
              {!isphoneNumberValid && (
                <p style={{ color: "red", textAlign: "center" }}>
                  Invalid phone number.
                </p>
              )}
            </div>
            <div className="formInput-container">
              <input
                type="email"
                placeholder="Enter your email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id={!isEmailValid ? "invalid" : ""}
                className="newsfeed-form"
                required
              ></input>
              {!isEmailValid && (
                <p style={{ color: "red", textAlign: "center" }}>
                  Please enter a valid email address.
                </p>
              )}
            </div>

            <button className="subscribe-btn" type="submit">
              Subscribe
            </button>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default Modal;
